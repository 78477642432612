import { Link } from "gatsby"
import React, { useMemo } from "react"

import styles from "./dropdownMenu.module.scss"

const DropdownMenu = ({
  data,
  className: externalClassName,
  expanded,
  closeMobileMenu,
}) => {
  const handleClick = (e) => {
    if (closeMobileMenu) {
      closeMobileMenu()
    } else {
      e.currentTarget.style.display = "none"
      const t = e.currentTarget
      setTimeout(() => (t.style.display = ""), 100)
    }
  }
  const wrapperClasses = useMemo(() => {
    const classes = [styles.wrapper, externalClassName]
    if (data.length > 4) classes.push(styles.threeCols)
    if (expanded) classes.push(styles.expanded)
    return classes.join(" ")
  }, [externalClassName, expanded, data])

  return (
    <div className={wrapperClasses} onClick={handleClick}>
      <div>
        {data.map((d) => (
          <Link
            key={d.label}
            to={d.link}
            className={styles.link}
            target={d.target}
          >
            {d.icon && (
              <div className={styles.iconWrapper}>
                <d.icon />
              </div>
            )}
            <span>{d.label}</span>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default DropdownMenu
