import { graphql, Link, StaticQuery } from "gatsby"
import throttle from 'lodash.throttle'
import React from "react"
import arrow_down from "../images/common/chevron_gray_down.svg"
import logo from "../images/logo.svg"
import logoAlternative from "../images/logo_white.svg"
import globalStyles from "../services/globalStyles.module.scss"
import { langPath } from "../services/languageService"
import { openModal } from "../services/modal"
import Button from "./button"
import styles from "./header.module.scss"
import DropdownMenu from "./dropdownMenu"
import {
  CollectionsIcon,
  CommunicationIcon,
  DiagramIcon,
  DownloadIcon,
  InjuryIcon,
  MonitoringIcon,
  OpenIcon,
  PlanningIcon,
  SupportIcon,
  ThumbUpIcon,
  VideoAnalysisIcon,
  BasketballIcon,
  FieldHockeyIcon,
  FloorballIcon,
  HandballIcon,
  IceHockeyIcon,
  PerformanceIcon,
  SoccerIcon,
  TennisIcon,
  VolleyballIcon
} from "./svgIcons"

const linkStyles = {
  textAlign: 'center',
  textDecoration: "none",
  fontWeight: "bold",
  fontSize: 16,
}

const NavLink = ({ path, className, style, data, placeholder, target, children }) =>
  target === "_blank" ? (
    <a
      className={className}
      style={{ ...linkStyles, ...style }}
      href={path}
      target={target}
    >
      {placeholder ? placeholder.toUpperCase() : path.toUpperCase()} {data}
      {children}
    </a>
  ) : (
    <Link
      className={className}
      style={{ ...linkStyles, ...style }}
      to={path}
      target={target}
    >
      {placeholder ? placeholder.toUpperCase() : path.toUpperCase()} {data}
      {children}
    </Link>
  )

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerOffsetReached: false,
      pricingRoute: props.pricingRoute,
      expandedMobileMenu: ''
    }
    this.onScroll = this.onScroll.bind(this);
    this.disableBodyScroll = this.disableBodyScroll.bind(this);
    this.changeWhiteFont = this.changeWhiteFont.bind(this);
    this.linkClicked = this.linkClicked.bind(this);
  }
  componentDidMount() {
    document.addEventListener("scroll", throttle(this.onScroll.bind(this), 250));
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", throttle(this.onScroll.bind(this), 250));
  }
  getBodyScrollTop() {
    const el = document.scrollingElement || document.documentElement;
    return el.scrollTop;
  }

  getBodyWidth() {
    const el = document.scrollingElement || document.documentElement;
    return el.clientWidth;
  }

  onScroll() {
    const { headerFullYOffset, mobileHeaderFullYOffset, fixed, noScrollAction, whiteFont, staticHeader } = this.props;
    const { header1, darkLogo, whiteLogo } = this.refs;

    if (!header1 || noScrollAction || (!fixed && !staticHeader)) {
      return;
    }
    if (header1 && this.getBodyWidth() < 1000) {
      if (this.getBodyScrollTop() > (mobileHeaderFullYOffset ? mobileHeaderFullYOffset : header1.offsetTop)) {
        header1.classList.add(styles.fadeInHeaderContainerAndLogo);
        whiteFont && darkLogo.classList.add(styles.fullOpacity);
        whiteFont && whiteLogo.classList.add(styles.noneOpacity);
        this.setState({ headerOffsetReached: true });
      } else {
        header1.classList.remove(styles.fadeInHeaderContainerAndLogo);
        whiteFont && darkLogo.classList.remove(styles.fullOpacity);
        whiteFont && whiteLogo.classList.remove(styles.noneOpacity);
        this.setState({ headerOffsetReached: false });

      }
    }
    else if (header1) {
      if (this.getBodyScrollTop() > (headerFullYOffset ? headerFullYOffset : header1.offsetTop)) {
        header1.classList.add((fixed || staticHeader) ? styles.sticky : globalStyles.hidden);
        whiteFont && darkLogo.classList.add(styles.fullOpacity);
        whiteFont && whiteLogo.classList.add(styles.noneOpacity);
        this.setState({ headerOffsetReached: true });

      } else {
        header1.classList.remove((fixed || staticHeader) ? styles.sticky : globalStyles.hidden);
        whiteFont && darkLogo.classList.remove(styles.fullOpacity);
        whiteFont && whiteLogo.classList.remove(styles.noneOpacity);
        this.setState({ headerOffsetReached: false });

      }
    }
  }

  // mobileSportsClicked() {
  //   var content = this.refs.collapse;
  //   if (content.style.maxHeight) {
  //     content.style.maxHeight = null;
  //   } else {
  //     content.style.maxHeight = content.scrollHeight + "px";
  //   }
  //   this.setState({ sportSelectionIconRotation: this.state.sportSelectionIconRotation + 180 })

  // }

  disableBodyScroll(e) {
    document.body.style.overflow = e.target.checked ? 'hidden' : 'scroll';
  }

  changeWhiteFont(e) {
    if (!this.state.headerOffsetReached) {
      const { whiteFont } = this.props;
      const { darkLogo, whiteLogo } = this.refs;

      if (e.target.checked) {
        whiteFont && darkLogo.classList.add(styles.fullOpacity);
        whiteFont && whiteLogo.classList.add(styles.noneOpacity);
      } else {
        whiteFont && darkLogo.classList.remove(styles.fullOpacity);
        whiteFont && whiteLogo.classList.remove(styles.noneOpacity);
      }

    }
  }



  // btnClick(sport) {
  //   const { languageCode } = this.props;
  //   this.linkClicked("sport")
  //   sport = sport.replace(' ', '');
  //   const link = languageCode ? languageCode == "en" ? "/sport/?type=" + sport : `/${languageCode}/sport/?type=${sport}` : "/sport?type=" + sport;
  //   this.toggleMobileMenu(false);
  //   this.hideDropdown();
  //   navigate(link);
  //   this.disableBodyScroll({ target: { checked: false } });
  // }

  linkClicked(urlParam) {
    const location = document.location.href.split("/");
    if (urlParam === location[location.length - 1]) this.toggleMobileMenu(false)
    this.disableBodyScroll({ target: { checked: false } });
  }

  toggleMobileMenu(open) {
    if (this.refs.checkbox) {
      this.refs.checkbox.checked = open;
    }
  }

  hideDropdown() {
    if (this.refs.dropdownContent) {
      this.refs.dropdownContent.style.opacity = 0;
      setTimeout(() => {
        if (this.refs.dropdownContent) {
          this.refs.dropdownContent.style.opacity = ''
          this.refs.dropdownContent.style.display = 'none'
          setTimeout(() => this.refs.dropdownContent.style.display = '', 250);
        }
      }, 300);
    }
  }

  componentWillReceiveProps(newProps) {
    // if (newProps.pricingRoute != this.state.pricingRoute) {
    //   this.setState({ pricingRoute: newProps.pricingRoute });
    // }
  }

  render() {
    const { sportContent } = this.props.data;
    const { fixed, headerBackground, whiteFont, nologo, className, translations, logoOnMobile, num, boxShadow, seeThrough, languageCode } = this.props;
    const { headerOffsetReached, pricingRoute } = this.state;
    const link = langPath(languageCode);
    return <header ref={"header" + num} className={[styles.header, seeThrough && !fixed && styles.seeThrough, boxShadow && styles.boxShadow, fixed && styles.preSticky, headerBackground && styles.headerBackground, className].join(' ')}>
      {!nologo &&
        <Link className={[styles.logoContainer, styles.logoDesktop].join(' ')} to={link('/')}>
          <img src={fixed ? logo : (whiteFont ? logoAlternative : logo)} style={{ marginBottom: 0 }} alt="XPS Network" />
        </Link>}
      {!nologo &&
        <Link className={[styles.logoContainer, styles.logoMobile].join(' ')} to={link('/')}>
          <img ref="darkLogo" className={whiteFont && styles.noneOpacity} src={logo} style={{ marginBottom: 0 }} alt="XPS Network" />
          {whiteFont && <img ref="whiteLogo" src={logoAlternative} style={{ marginBottom: 0 }} alt="XPS Network" />}
        </Link>}
      {logoOnMobile &&
        <Link onClick={() => { this.linkClicked("") }} to={link('/')} className={[styles.logoContainer, styles.logoMobile].join(' ')}>
          <img src={fixed ? logo : (whiteFont ? logoAlternative : logo)} style={{ marginBottom: 0 }} alt="XPS Network" />
        </Link>}

      {this.props.hideNav ? null :
        <div className={styles.desktopNavContainer}>
          <nav>
            <NavLink 
              style={(whiteFont && !headerOffsetReached) && { color: "white" }} 
              className={[styles.navLink, whiteFont ? styles.navLinkHover2 : styles.navLinkHover].join(' ')} 
              placeholder={translations.sports_title} 
              path={null}
            >
              <DropdownMenu
                className={styles.dropdownWrapper} 
                data={[
                  {label: translations.sports['basketball'], icon: BasketballIcon, link: link(`/sport/?type=Basketball`)},
                  {label: translations.sports['field_hockey'], icon: FieldHockeyIcon, link: link(`/sport/?type=FieldHockey`)},
                  {label: translations.sports['floorball'], icon: FloorballIcon, link: link(`/sport/?type=Floorball`)},
                  {label: translations.sports['handball'], icon: HandballIcon, link: link(`/sport/?type=Handball`)},
                  {label: translations.sports['ice_hockey'], icon: IceHockeyIcon, link: link(`/sport/?type=IceHockey`)},
                  {label: translations.sports['performance'], icon: PerformanceIcon, link: link(`/sport/?type=Performance`)},
                  {label: translations.sports['soccer'], icon: SoccerIcon, link: link(`/sport/?type=Soccer`)},
                  {label: translations.sports['tennis'], icon: TennisIcon, link: link(`/sport/?type=Tennis`)},
                  {label: translations.sports['volleyball'], icon: VolleyballIcon, link: link(`/sport/?type=Volleyball`)},
                  {label: translations.sports['other_sports'], link: link(`/sport/?type=Other`)}
                ]}
               />
            </NavLink>
            <NavLink 
              style={(whiteFont && !headerOffsetReached) && { color: "white" }} 
              className={[styles.navLink, whiteFont ? styles.navLinkHover2 : styles.navLinkHover].join(' ')} 
              placeholder={translations.blog.features_title} 
              path={link('/features')}
            >
              <DropdownMenu
                className={styles.dropdownWrapper} 
                data={[
                  {label: 'PLANNING & MANAGEMENT', icon: PlanningIcon, link: link('/features#planning')},
                  {label: 'MONITORING & EVALUATIONS', icon: MonitoringIcon, link: link('/features#monitoring')},
                  {label: 'COMMUNICATION', icon: CommunicationIcon, link: link('/features#communication')},
                  {label: 'TACTICS & DIAGRAMS', icon: DiagramIcon, link: link('/features#tactics')},
                  {label: 'VIDEO ANALYSIS', icon: VideoAnalysisIcon, link: link('/features#video_analysis')},
                  {label: 'COLLECTIONS', icon: CollectionsIcon, link: link('/features#collections')},
                  {label: 'INJURY MANAGEMENT', icon: InjuryIcon, link: link('/features#injury_management')},
                  {label: 'ALL FEATURES', link: link('/features')},
                ]}
               />
            </NavLink>
            <NavLink style={(whiteFont && !headerOffsetReached) && { color: "white" }} className={[styles.navLink, whiteFont ? styles.navLinkHover2 : styles.navLinkHover].join(' ')} placeholder={translations.user_stories_title} path={'https://stories.sidelinesports.com'} target="_blank"/>
            <NavLink 
              style={(whiteFont && !headerOffsetReached) && { color: "white" }} 
              className={[styles.navLink, whiteFont ? styles.navLinkHover2 : styles.navLinkHover].join(' ')} 
              placeholder={translations.resources_title} 
              path={null}
              target="_blank"
            >
              <DropdownMenu
                className={styles.dropdownWrapper} 
                data={[
                  {label: translations.support_title, icon: SupportIcon, link: link('/support')},
                  {label: translations.blog_title, icon: OpenIcon, link: `https://sidelinesports.com${this.props.languageCode === 'cs' ? '/cs' : ''}/blog`, target: "_blank"},
                  {label: translations.downloads_title, icon: DownloadIcon, link: `https://sidelinesports.com${this.props.languageCode === 'en' ? '' : `/${this.props.languageCode}`}/downloads`, target: "_blank"},
                  {label: translations.social_media_title, icon: ThumbUpIcon, link: `https://sidelinesports.com${this.props.languageCode === 'en' ? '' : `/${this.props.languageCode}`}/socialMedia`, target: "_blank"},
                ]}
               />
            </NavLink>
            <button className={styles.navBtn} onClick={openModal}>{translations.try_xps.try_xps_for_free.toUpperCase()}</button>
          </nav>
        </div>
      }
      {this.props.hideNav ? null :
        <nav role="navigation" className={styles.mobileNavContainer}>
          <div className={styles.menuToggle}>
            <input ref="checkbox" type="checkbox" onChange={(e) => { this.disableBodyScroll(e); this.changeWhiteFont(e) }} />
            <span className={(whiteFont && !headerOffsetReached) ? styles.whiteSpan : styles.blackSpan}></span>
            <span className={(whiteFont && !headerOffsetReached) ? styles.whiteSpan : styles.blackSpan}></span>
            <span className={(whiteFont && !headerOffsetReached) ? styles.whiteSpan : styles.blackSpan}></span>
            <ul className={styles.menu}>
              <div className={styles.relativeContainer}>
                <div className={styles.emptyRow}>
                </div>
                <div style={{ position: "fixed", top: 0, left: 0, background: "white", height: 69, width: "100%" }} className={styles.emptyRow}>
                </div>
                <li onClick={()=> this.setState({expandedMobileMenu: this.state.expandedMobileMenu === 'sports' ? '' : 'sports'})}>{translations.sports_title.toUpperCase()} <img src={arrow_down} style={{ transform: `rotate(${this.state.expandedMobileMenu === 'sports' ? 180 : 0}deg)` }} className={styles.dropDownIcon} /> </li>
                <DropdownMenu
                expanded={this.state.expandedMobileMenu === 'sports'}
                closeMobileMenu={()=>this.toggleMobileMenu(false)}
                data={[
                  {label: translations.sports['basketball'], icon: BasketballIcon, link: link('/sport/?type=Basketball')},
                  {label: translations.sports['field_hockey'], icon: FieldHockeyIcon, link: link('/sport/?type=FieldHockey')},
                  {label: translations.sports['floorball'], icon: FloorballIcon, link: link('/sport/?type=Floorball')},
                  {label: translations.sports['handball'], icon: HandballIcon, link: link('/sport/?type=Handball')},
                  {label: translations.sports['ice_hockey'], icon: IceHockeyIcon, link: link('/sport/?type=IceHockey')},
                  {label: translations.sports['performance'], icon: PerformanceIcon, link: link('/sport/?type=Performance')},
                  {label: translations.sports['soccer'], icon: SoccerIcon, link: link('/sport/?type=Soccer')},
                  {label: translations.sports['tennis'], icon: TennisIcon, link: link('/sport/?type=Tennis')},
                  {label: translations.sports['volleyball'], icon: VolleyballIcon, link: link('/sport/?type=Volleyball')},
                  {label: translations.sports['other_sports'], link: link('/sport/?type=Other')},
                ]}
               />
               <li onClick={()=> this.setState({expandedMobileMenu: this.state.expandedMobileMenu === 'features' ? '' : 'features'})}>{translations.blog?.features_title.toUpperCase()} <img src={arrow_down} style={{ transform: `rotate(${this.state.expandedMobileMenu === 'features' ? 180 : 0}deg)` }} className={styles.dropDownIcon} /> </li>
               <DropdownMenu
                expanded={this.state.expandedMobileMenu === 'features'}
                closeMobileMenu={()=>this.toggleMobileMenu(false)}
                data={[
                  {label: 'PLANNING & MANAGEMENT', icon: PlanningIcon, link: link('/features#planning')},
                  {label: 'MONITORING & EVALUATIONS', icon: MonitoringIcon, link: link('/features#monitoring')},
                  {label: 'COMMUNICATION', icon: CommunicationIcon, link: link('/features#communication')},
                  {label: 'TACTICS & DIAGRAMS', icon: DiagramIcon, link: link('/features#tactics')},
                  {label: 'VIDEO ANALYSIS', icon: VideoAnalysisIcon, link: link('/features#video_analysis')},
                  {label: 'COLLECTIONS', icon: CollectionsIcon, link: link('/features#collections')},
                  {label: 'INJURY MANAGEMENT', icon: InjuryIcon, link: link('/features#injury_management')},
                  {label: 'ALL FEATURES', link: link('/features')},
                ]}
                />
                <Link to="https://stories.sidelinesports.com" target="_blank"><li>{translations.user_stories_title.toUpperCase()}</li></Link>
                <li onClick={()=> this.setState({expandedMobileMenu: this.state.expandedMobileMenu === 'resources' ? '' : 'resources'})}>{translations.resources_title.toUpperCase()} <img src={arrow_down} style={{ transform: `rotate(${this.state.expandedMobileMenu === 'resources' ? 180 : 0}deg)` }} className={styles.dropDownIcon} /> </li>
                <DropdownMenu
                  expanded={this.state.expandedMobileMenu === 'resources'}
                  closeMobileMenu={()=>this.toggleMobileMenu(false)}
                  data={[
                    {label: translations.support_title, icon: SupportIcon, link: link('/support')},
                    {label: translations.blog_title, icon: OpenIcon, link: `https://sidelinesports.com${this.props.languageCode === 'cs' ? '/cs' : ''}/blog`, target: "_blank"},
                    {label: translations.downloads_title, icon: DownloadIcon, link: `https://sidelinesports.com${this.props.languageCode === 'en' ? '' : `/${this.props.languageCode}`}/downloads`, target: "_blank"},
                    {label: translations.social_media_title, icon: ThumbUpIcon, link: `https://sidelinesports.com${this.props.languageCode === 'en' ? '' : `/${this.props.languageCode}`}/socialMedia`, target: "_blank"},
                  ]}
                />
                <div className={styles.btnContainer}>
                  <div>
                    <Button onClick={openModal} classStyle={[styles.button, styles.gray].join(' ')}>
                      {translations.try_xps.try_xps_for_free.toUpperCase()}
                    </Button>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </nav>
      }
    </header>
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        sportContent: allMarkdownRemark(filter: { fileAbsolutePath: { regex:"\/sportsContent/" } }) {
          edges {
            node {
              frontmatter {
                title
                icon{
                  name
                  extension
                  relativePath
                  publicURL
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)

export const query = graphql`
fragment HeaderTranslations on TextsYaml{
  sports_title
  pricing_title
  support_title
  user_stories_title
  blog_title
  resources_title
  downloads_title
  social_media_title
  blog {
    features_title
  }
  try_xps {
    try_xps_for_free
    no_credit_card
  }
  sports {
    basketball
    field_hockey
    floorball
    handball
    ice_hockey
    other_sports
    performance
    soccer
    tennis
    volleyball
  }
}
`
